import React, { Suspense, useEffect, lazy } from "react";

const Spline = lazy(() => import("@splinetool/react-spline"));

export default function Laauto24() {
  
useEffect(()=>{
  const headers = document.querySelectorAll("header");

  headers.forEach((h) => {
    h.style.display = "none";
  })

},[])
  
return (
    <div
      style={{
        padding: "24px 12px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "'circulat', sans-serif",
        backgroundColor: "black",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99999999999999999,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 12,
          maxWidth: 340,
          maxHeight: 520,
          margin: "auto",
          width: "100%",
          flex: 1,
          transform: "translateY(-10px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: 4,
              justifyContent: "center",
              paddingBottom: 4,
              marginBottom: 4,
            }}
          >
            <img
              src="/icons/logo.svg"
              alt={"SPARQ's logo"}
              style={{ width: 120, height: 38 }}
              width={120}
              height={38}
            />
          </div>
          <div>
            <p
              style={{
                fontSize: 40,
                textAlign: "center",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              Turn your car
            </p>
            <p
              style={{
                fontSize: 40,
                textAlign: "center",
                fontWeight: "bold",
                color: "#fff",
                marginTop: -12,
              }}
            >
              into an <span style={{ color: "#FE522E" }}>AI</span>
            </p>
          </div>
        </div>

        <div
          style={{
            position: "relative",
            width: "100%",
            flex: 1,
            maxHeight: 300,
            marginInline: "auto",
          }}
        >
          <div
            style={{
              transform: "translateY(45px) translateX(16px) scale(1.45)",
              aspectRatio: "1 / 1",
              pointerEvents: "none",
              position: "absolute",
              top: 0,
              width: 300,
              zIndex: 6,
            }}
          >
            <div
              style={{
                transform: "translateX(-50%) translateY(-50%) scale(0.4)",
                position: "absolute",
                height: "300%",
                width: "300%",
                left: "50%",
                top: "50%",
              }}
            >
              <Model />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 24, paddingBottom: 17, width: "100%" }}>
          <p
            style={{
              textAlign: "center",
              color: "#fff",
              opacity: 0.5,
            }}
          >
            Find SPARQ to get yours for free
          </p>
        </div>
      </div>
    </div>
  );
}

const Model = () => {
  return (
    <Suspense>
      <Spline
        style={{
          width: "700px",
          height: "750px",
          overflow: "hidden",
          transform: "scale(0.75)",
          margin: "auto",
        }}
        scene="https://prod.spline.design/oqexVXpS59h54Vdy/scene.splinecode"
      />
    </Suspense>
  );
};
